import React, { useEffect, useState } from 'react';
import { GET_ALL_PRODUCTS } from '../../queries';
import { client } from './hydrogenClient';
import { AddToCartButton, Money, ProductProvider } from '@shopify/hydrogen-react';

const ProductList = () => {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchProducts() {
      const res = fetch(client.getStorefrontApiUrl(), {
        body: JSON.stringify({
          query: GET_ALL_PRODUCTS,
        }),
        headers: client.getPublicTokenHeaders(),
        method: 'POST',
      });
      const json = await (await res).json();
      setData(json.data);
    }
    fetchProducts();
  }, [])

  // TODO: Better empty state handling
  if (!data) {
    return (
      <div>No products found</div>
    )
  }

  function getProductGrid() {
    const products = data.products.edges.map(edge => edge.node);
    return (
      <div style={gridStyle}>
        {products.map((product) => {
          const variantId = product.variants.edges[0].node.id;
          const forSale = product.variants.edges[0].node.availableForSale;
          const imgSrc = product.images.edges[0].node.url;
          return (
            <ProductProvider data={product} key={product.id}>
              <div key={product.id} className='product-card' style={bgImgStyle(imgSrc)}>
                <h3>{product.title}</h3>
                <p>{product.description}</p>
                <p>
                  <Money data={product.variants.edges[0].node.priceV2} />
                </p>
                <AddToCartButton
                  className='button button-green'
                  variantId={variantId}
                  disabled={!forSale}
                >
                  {forSale ? 'Add to cart' : 'Out of Stock'}
                </AddToCartButton>
              </div>
            </ProductProvider>
          );
        })}
      </div>
    );
  }

  const gridStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  };

  const bgImgStyle = (imageUrl) => {
    return {
      backgroundImage: `url(${imageUrl})`,
    };
  };

  return getProductGrid();
};

export default ProductList;
