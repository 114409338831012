import React from 'react';
import { CartCheckoutButton, CartCost, CartLineProvider, CartLineQuantityAdjustButton, Money, useCart } from '@shopify/hydrogen-react';

const Cart = () => {
  const { lines } = useCart();
  const emptyCart = lines.length === 0;
  const numItems = emptyCart ? 0 : lines.reduce((acc, curr) => acc + curr.quantity, 0);
  const ItemizedList = () => {
    return lines.map((line) => (
      <CartLineProvider line={line} key={line.id}>
        <div className='cart-item-container'>
          <div className='cart-item-title'>{line.merchandise.product.title}</div>
          <div className='cart-item-row'>
            <img className='preview' src={line.merchandise.image.url} />
            <div className='col'>
              Each
              <Money data={line.merchandise.price} />
            </div>
            <div className='col'>
              <div>Quantity</div>
              <div className='quantity-adjust'>
                <CartLineQuantityAdjustButton adjust="decrease">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" /></svg>
                </CartLineQuantityAdjustButton>
                <div>{line.quantity}</div>
                <CartLineQuantityAdjustButton adjust="increase">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" /></svg>
                </CartLineQuantityAdjustButton>
              </div>
            </div>
            <div className='col'>
              <div>Total</div>
              <Money data={line.cost.totalAmount} />
            </div>
            <CartLineQuantityAdjustButton adjust="remove">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
            </CartLineQuantityAdjustButton>
          </div>
        </div>
      </CartLineProvider>
    ));
  }

  return (
    <div>
      <h3>Shopping Cart</h3>
      {!emptyCart && <div>{numItems} item{numItems > 1 ? 's' : ''}</div>}
      {emptyCart && <div>No items in cart</div>}
      <div className='item-list'>
        <ItemizedList />
      </div>

      {!emptyCart && <>
        <h3>Order Summary</h3>
        <div className='order-summary-container'>
          <div className='order-row'>
            <div>Subtotal</div>
            <CartCost amountType='subtotal' />
          </div>
          <div className='order-row'>
            <div>Estimated Shipping</div>
            <div>See at checkout</div>
          </div>
          <div className='order-row'>
            <div>Tax</div>
            <CartCost amountType='tax' />
          </div>
          <hr />
          <div className='order-row'>
            <div>Estimated Total</div>
            <CartCost />
          </div>
          <CartCheckoutButton className={`button button-${emptyCart ? 'gray' : 'green'}`} disabled={emptyCart}>Checkout Now</CartCheckoutButton>
        </div>
      </>
      }
    </div>
  );
};

export default Cart;
